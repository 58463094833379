
.auth-form-header {
    color: cyan;
    margin-bottom: 20px;
}

.auth-container{
        background-color: 'background.default';
        background-repeat: no-repeat;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
        text-align: center;
        padding-top: 100px;
}
.logo-img{
    height: 30%;
    width: 30%;
}