.notice-container {
  position: relative;
  overflow: hidden;
}

.notice-enter {
  opacity: 0;
  transform: translateX(100%);
}

.notice-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.notice-exit {
  opacity: 1;
  transform: translateX(0);
}

.notice-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 500ms, transform 500ms;
}
